<template>
  <div class="bg">
    <div style="height: 5.625rem"></div>
    <!-- 创作 -->
    <!-- <div class="pt-50 pb-40 flex flex-column align-center justify-center bg-F1F2F3">
      <div class="font-26 text-222222 mb-20">创作</div>
      <div class="flex align-center">
        <div class="mr-15 cursorp" @click="goMycreation">
          <el-image
            :src="require('@/assets/img/mywork.png')"
            style="width: 7.8125rem; height: 2.1875rem"
            fit="cover"
          ></el-image>
        </div>
        <div class="cursorp addchuangzuo" @click="goupwork">
          加入创作联盟
        </div>
      </div>
    </div> -->
    <!-- 创作导航 -->
    <div class="px-60 pt-40 bg-white">
      <div>
        <div class="font-26 text-222222">原创世界</div>
      </div>
      <div class="flex align-center">
        <div class="flex align-center flex-shrink mr-30">
          <div class="mr-15 cursorp" @click="goMycreation">
            <el-image
              :src="require('@/assets/img/mywork.png')"
              style="width: 7.8125rem; height: 2.1875rem"
              fit="cover"
            ></el-image>
          </div>
          <div class="cursorp addchuangzuo" @click="goupwork">加入创作联盟</div>
        </div>
        <div class="label">
          <ul class="label_ul flex align-center">
            <li
              v-for="(item, index) in labelList"
              :key="index"
              class="label_ul_li"
              :class="labelid == item.id ? 'label_ul_liact' : ''"
              @click="labelcut(item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <!-- <div class="container">
      <div class="waterfall">
        <my-waterfall
          :data="cardList"
          @loadmore="loadmore"
          @godetails="godetails"
        ></my-waterfall>
      </div>
      <div class="font-14 text-666666 mt-80 text-center">
        {{ bottomtext }}
      </div>
    </div> -->
    <div class="flex flex-wrap mt-45 px-88">
      <!-- mr-50 -->
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="mb-20 cursorp px-20"
        style="width: 20%; box-sizing: border-box"
        @click="godetails(item)"
      >
        <div class="bg-white">
          <el-image
            class="rounded3"
            style="height: 10.625rem; width: 100%"
            :src="item.img"
            fit="cover"
          ></el-image>
          <div class="p-15">
            <div class="item-desc">{{ item.title }}</div>
            <div class="flex align-center justify-between">
              <div class="flex align-center">
                <el-image
                  style="
                    border-radius: 50%;
                    width: 1.625rem;
                    height: 1.625rem;
                    margin-right: 0.5625rem;
                  "
                  :src="item.avatar"
                  fit="cover"
                ></el-image>
                <div class="name">{{ item.username }}</div>
              </div>
              <div class="flex align-center">
                <el-image
                  style="width: 1.125rem; height: 1rem; margin-right: 0.3125rem"
                  :src="require('@/assets/img/zan_act.png')"
                  fit="cover"
                  v-if="item.is_fabulous == 1"
                ></el-image>
                <el-image
                  style="width: 1.125rem; height: 1rem; margin-right: 0.3125rem"
                  :src="require('@/assets/img/zan.png')"
                  fit="cover"
                  v-else
                ></el-image>
                <div class="like-total">{{ item.fabulous_nums }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-center justify-center py-20 border-top border-ligit-BFBFBF">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import req from "../../utils/req";
import myWaterfall from "@/components/myWaterfall";
export default {
  components: { myWaterfall },
  data() {
    return {
      page_nums: 0,
      cardList: [],
      labelList: [],
      labelid: "",
      bottomtext: "滑动加载更多~",
      page: 1,
      userId: localStorage.getItem("uid"),
    };
  },
  mounted() {
    this.getlabel();
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 跳转上传作品
    goupwork() {
      // this.$router.push({
      //   path: "/upwork",
      //   query: { type: "upwork" },
      // });
      this.$router.push({
        path: "/richtext",
        query: {type: "richtext", foottype: 8 },
      });
    },
    // 跳转我的创作
    goMycreation() {
      this.$router.push({
        path: "/creationMy",
        query: { type: "creationMy" },
      });
    },
    // 跳转详情
    godetails(e) {
      // console.log(e);creationdetails
      // this.$router.push({
      //   path: "/creationdetails",
      //   query: { type: "creationdetails", moveid: e.id },
      // });
      this.$router.push({
        path: "/creationDetail2",
        query: { type: "creationDetail2", moveid: e.id },
      });
    },
    // 滚动底部加载更多
    loadmore() {
      console.log("滑动到底部~");
      this.page = this.page + 1;
      this.getdata();
    },
    // 切换标签
    labelcut(id) {
      this.labelid = id;
      this.cardList = [];
      this.page = 1;
      this.getdata();
    },
    // 获取标签
    getlabel() {
      let that = this;
      req
        .post("original/label", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.labelList = res.data;
          that.labelid = res.data[0].id;
          that.page = 1;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("original/original", {
          label_id: that.labelid,
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.cardList = res.data.original.data;
          that.page_nums = res.data.original.last_page;
          // this.bottomtext = "滑动加载更多~";
          // if (this.page == 1) {
          //   this.cardList = res.data.original.data;
          // } else {
          //   if (res.data.original.data && res.data.original.data.length) {
          //     this.cardList = [...this.cardList, ...res.data.original.data];
          //   } else {
          //     this.bottomtext = "没有更多数据~";
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e7524c !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #e6e6e6 !important;
}
</style>
<style lang="scss" scoped>
.bg {
  background-color: #f1f2f3;
}
.item-desc {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.4375rem;
  color: #000000;
}
.like-total {
  font-size: 0.75rem;
  color: #bbbbbb;
}
.name {
  font-size: 0.75rem;
  color: #666666;
}

.container {
  background-color: #f1f2f3;
  // width: 100%;
  padding: 3.4375rem 5rem;
}
.addchuangzuo {
  background: linear-gradient(0deg, #ff534d, #ff8f6d);
  border-radius: 6.25rem;
  padding: 0 0.9375rem;
  font-size: 0.875rem;
  line-height: 2.1875rem;
  color: #ffffff;
}
.label {
  width: 100%;
  &_ul {
    flex-wrap: wrap;
    // padding: 0 5.8125rem;
    &_li {
      height: 5.9375rem;
      line-height: 5.9375rem;
      font-size: 1.125rem;
      // margin-right: 3.625rem;
      margin-right: 1.875rem;
      cursor: pointer;
    }
    &_liact {
      font-size: 1.3125rem;
      color: #ff5950;
      position: relative;
    }
    &_liact::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 26%;
      width: 50%;
      height: 0.25rem;
      background: linear-gradient(0deg, #ff534d, #ff8f6d);
      border-radius: 0.125rem;
    }
  }
}
</style>
