<template>
  <div>
    <waterfall :col="col" :data="data" @loadmore="loadmore">
      <!--  @loadmore="loadmore"
          @scroll="scroll" -->
      <template>
        <div
          class="cell-item"
          v-for="(item, index) in data"
          :key="index"
          @click="godetails(item)"
        >
          <img :src="item.img" alt="加载错误" />
          <div class="item-body">
            <div class="item-desc">{{ item.title }}</div>
            <div class="item-footer">
              <div class="footer-left">
                <img :src="item.avatar" alt="" srcset="" />
                <div class="name">{{ item.username }}</div>
              </div>
              <div class="like">
                <!-- <img
                  src="@/assets/img/zan_act.png"
                  alt=""
                  style="width: 1.125rem; height: 1rem"
                  width="1.125rem"
                  height="1rem"
                  v-if="item.is_fabulous == 1"
                /> -->
                <el-image
                  style="width: 1.125rem; height: 1rem"
                  :src="require('@/assets/img/zan_act.png')"
                  fit="cover"
                  v-if="item.is_fabulous == 1"
                ></el-image>
                <!-- <img
                  src="@/assets/img/zan.png"
                  alt=""
                  width="1.125rem"
                  height="1rem"
                  v-else
                /> -->
                <el-image
                  style="width: 1.125rem; height: 1rem"
                  :src="require('@/assets/img/zan.png')"
                  fit="cover"
                  v-else
                ></el-image>
                <div class="like-total">{{ item.fabulous_nums }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </waterfall>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      col: 5,
    };
  },
  methods: {
    godetails(item) {
      this.$emit("godetails", item);
    },
    loadmore() {
      this.$emit("loadmore");
    },
  },
};
</script>
<style lang="scss" scoped>
.item-body {
  padding: 1.5625rem 1.125rem;
  // margin: 0.5625rem;
  .item-desc {
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0.4375rem;
    color: #000000;
  }
  .item-footer {
    display: flex;
    justify-content: space-between;
    .footer-left {
      display: flex;
      align-items: center;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #666666;
      img {
        border-radius: 50%;
        width: 1.625rem;
        height: 1.625rem;
        margin-right: 0.5625rem;
      }
    }
    .like {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #bbbbbb;
      img {
        width: 1.125rem !important;
        height: 1rem !important;
        margin-right: 0.3125rem;
      }
    }
  }
}
.cell-item {
  margin: 0 0.5625rem;
  // width: 100%;
  margin-bottom: 1.25rem;
  background: #ffffff;
  // border: 2px solid #f0f0f0;
  border-radius: 0.1875rem;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    max-height: 31.8125rem;
    display: block;
  }
}
</style>
